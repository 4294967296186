<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12"></div>
      <div class="col-md-12">
        <vuestic-widget :headerText="header">
          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Sales2.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h2 style="font-weight: 600">
                    Ventas<br />{{ analytic_station.total_sales_ok }} USD
                  </h2>
                </div>

                <ul class="list-group" style="font-weight: 600">
                  <!-- <li class="list-group-item" style="margin-bottom:-20px;">
                                        <h5 style="font-weight:600">Total Ventas</h5>
                                        <h4 style="font-weight:600">{{analytic_station.total_sales_ok}} USD</h4>
                                    </li>-->

                  <li class="list-group-item">
                    Completadas: {{ analytic_station.total_sales_ok }} USD
                  </li>
                  <li class="list-group-item">
                    Devueltas: {{ analytic_station.standard_total_sales_fail }}
                    USD
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Devices.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h2 style="font-weight: 600">
                    Dispositivos <br />{{ analytic_station.cant_devices }}
                  </h2>
                </div>

                <ul class="list-group" style="font-weight: 600">
                  <li class="list-group-item">
                    Por Operador: {{ analytic_station.perOperator }}
                  </li>
                  <li class="list-group-item">
                    Prom. ventas: {{ analytic_station.avg_sales_device }} USD
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Customer.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h2 style="font-weight: 600">
                    Clientes <br />{{ analytic_station.customerCount }}
                  </h2>
                </div>

                <ul class="list-group" style="font-weight: 600">
                  <li class="list-group-item">
                    Por Día: {{ analytic_station.avg_sales_perday }} USD
                  </li>
                  <li class="list-group-item">
                    Prom. ventas: {{ analytic_station.avg_sales_customer }}
                    USD
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-3">
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  src="@/assets/images/Operator.jpg"
                  alt="Card image cap"
                />
                <div class="card-img-overlay" style="color: white">
                  <h2 style="font-weight: 600">
                    Operadores <br />{{ analytic_station.cant_staff }}
                  </h2>
                </div>

                <ul class="list-group" style="font-weight: 600">
                  <li class="list-group-item">
                    Avg Tips: {{ analytic_station.avg_tips }}
                  </li>
                  <li class="list-group-item">
                    Prom. ventas: {{ analytic_station.avg_sales_operator }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <button
            class="btn-button btn-primary btn-micro my-4"
            @click="showModal = true"
          >
            Descargar reporte
          </button>

          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Cantidad de Ventas</th>
                      <th scope="col">Ventas Concretadas</th>
                      <th scope="col">Ventas Devueltas</th>
                      <th scope="col">Ingreso Total</th>
                      <th scope="col">Ingreso Concretado</th>
                      <th scope="col">Ingreso Devuelto</th>
                    </tr>
                  </thead>
                  <tbody v-if="analytic_station">
                    <tr>
                      <td>{{ analytic_station.count_total_sales }}</td>
                      <td>{{ analytic_station.count_total_sales_ok }}</td>
                      <td>{{ analytic_station.count_total_sales_fail }}</td>
                      <td>{{ analytic_station.total_sales }}</td>
                      <td>{{ analytic_station.total_sales_ok }}</td>
                      <td>{{ analytic_station.total_sales_fail }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </vuestic-widget>

        <vuestic-widget>
          <button class="btn-button btn-primary btn-micro" @click="backPage">
            Volver a estaciones
          </button>
          <button class="btn-button btn-warning btn-micro mx-3" @click="showDuplicateDetail">
            Chequear transacciones
          </button>

          <button class="btn-button btn-success btn-micro mx-3" @click="showDeviceDetail">
            Totales por dispositivo
          </button>

          <button class="btn-button btn-warning btn-micro mx-3" @click="showVisorStation">
            Visor de analíticas
          </button>

          <vuestic-modal
          id="duplicateDetail"
            :show="showModalDuplicate"
            ref="duplicateDetailModal"
            :no-buttons="true"
            @cancel="closeModal"
          >
            <div slot="title">
              Transacciones que requieren revisión: {{ duplicateTransaction.length }},  
           Monto aproximado: {{ amountDuplicated }}</div>
            <div>
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th scope="col">Monto</th>
                    <th scope="col">Dispositivo</th>
                    <th scope="col">Timestamp</th>
                    <th scope="col">Staff</th>
                    <th scope="col">Incidencias</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="detailt in duplicateTransaction"
                    :key="detailt.id"
                    style="text-align: center"
                  >
                    <td>{{ detailt.standard_credit }}</td>
                    <td>{{ detailt.device.name }}</td>
                    <td>{{ detailt.timestamp }}</td>
                    <td>{{ detailt.staff.name+' - '+detailt.staff.code }}</td>
                    
                    <td>{{ detailt.count }}</td>
                  </tr>
                  <tr
                    style="
                      background-color: #e3e3e3;
                      text-align: center;
                      font-size: 22px;
                    "
                  >
                    
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </vuestic-modal>


          <vuestic-modal
          id="deviceDetailModal"
            :show="showModalDeviceDetail"
            ref="deviceDetailModal"
            :no-buttons="true"
            @cancel="closeModal"
          >
            <div slot="title">
              Totales por dispositivo</div>
            <div>
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th scope="col">Dispositivo</th>
                    <th scope="col">Total</th>
                 
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="totald in totalDevices"
                    :key="totald.id"
                    style="text-align: center"
                  >
                    <td>{{ totald.device.name }}</td>
                    <td>{{ totald.total_standard_credit }}</td>
                   
                  </tr>
                  <tr
                    style="
                      background-color: #e3e3e3;
                      text-align: center;
                      font-size: 22px;
                    "
                  >
                    
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </vuestic-modal>
          <div class="col-md-12">
            <vuestic-tabs
              class="tabs"
              :names="['Ventas', 'Productos', 'Dispositivos', 'Staff']"
            >
              <div slot="Ventas">
                <TableSalesStation></TableSalesStation>
              </div>
              <div slot="Productos">
                <AnalyticProductByStation></AnalyticProductByStation>
              </div>
              <div slot="Dispositivos">
                <TableDeviceByStation></TableDeviceByStation>
              </div>
              <div slot="Staff">
                <TableStaffByStation></TableStaffByStation>
              </div>
            </vuestic-tabs>
          </div>
        </vuestic-widget>

        <div class="row">
          <!-- <div class="col-md-6">
                       <vuestic-widget :headerText="headerChart1">
                         <div class="row">
                           <vuestic-chart v-bind:data="chartData" :options="chartOptions" type="vertical-bar"></vuestic-chart>

                         </div>
                       </vuestic-widget>
                     </div> -->

          <div class="col-md-6">
            <!--   <vuestic-widget :headerText="headerChart2">
                          <div class="row">

                            <vuestic-chart v-bind:data="chartData" :options="chartOptions" type="donut"></vuestic-chart>
                          </div>
                        </vuestic-widget> -->
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal
      :show="showModal"
      ref="transactionDetailModal"
      okText="Descargar reporte"
      @ok="downloadStationSalesReport"
      cancelText="Cancelar"
      @cancel="closeModal"
    >
      <div slot="title">Escoge los filtros del reporte de ventas</div>
      <vuestic-simple-select
        label="Staff"
        v-model="selectedStaff"
        name="id_staff"
        :required="true"
        :options="staffData"
        optionKey="name"
      >
      </vuestic-simple-select>
      <vuestic-simple-select
        label="Dispositivo"
        v-model="selectedDevice"
        name="id_device"
        :required="true"
        :options="devicesData"
        optionKey="name"
      >
      </vuestic-simple-select>
    </vuestic-modal>
  </div>
</template>

<script>
import Vue from "vue";
import TableStation from "../tables/TableStation/TableStation.vue";
import TableSalesStation from "../tables/TableStation/TableSalesStation.vue";
import TableDeviceByStation from "../tables/TableDeviceByStation/TableDeviceByStation.vue";
import TableStaffByStation from "../tables/TableStaffByStation/TableStaffByStation.vue";
import AnalyticProductByStation from "./AnalyticProductByStation.vue";
import moment from "moment";
import { SpringSpinner } from "epic-spinners";
import { mapState, mapActions } from "vuex";
import * as Config from "../../../config";

export default {
  name: "AnalyticByStation",
  components: {
    SpringSpinner,
    TableStation,
    TableSalesStation,
    AnalyticProductByStation,
    TableDeviceByStation,
    TableStaffByStation,
  },
  mounted() {
    // checkbox and radios
    var idstation = this.$route.params.idstation;
    this.loadAnalyticStation(idstation)
      .then((data) => {
        this.analytic_station = data;
      })
      .catch((data) => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning",
        });
      });
    this.getStaffByEvent();
    this.getDevicesByEvent();
  },
  computed: {
    ...mapState({
      //  typestationlist: state => state.typestation.typestations,
      // analytic_station: state => state.analytic.station,
      idevent: (state) => state.event.event.id,
      codeevent: (state) => state.event.event.code,
      station: (state) => state.station.station,
    }),
  },
  data() {
    return {
      showModal: false,
      showModalDuplicate: false,
      showModalDeviceDetail: false,
      header: "Analíticas de Estación",
      headerChart1: "Sales per Hour (En desarrollo)",
      headerChart2: "Top Products (En Desarrollo)",
      duplicateTransaction:[],
      totalDevices:[],
      amountDuplicated:0,
      // typestation: [],
      //  categorystation: [],
      // typestationlist: [],
      name: "",
      number: "",
      selectedStaff: null,
      selectedDevice: null,
      staffData: [],
      devicesData: [],
      analytic_station: {
        avg_sales_customer: 0,
        avg_sales_device: 0,
        avg_sales_operator: 0,
        avg_sales_perday: 0,
        avg_tips: 0,
        cant_devices: 0,
        cant_staff: 0,
        count_total_sales: 0,
        count_total_sales_fail: 0,
        count_total_sales_ok: 0,
        customerCount: 0,
        perOperator: 0,
        standard_total_sales_fail: 0,
        standard_total_sales_ok: 0,
        standard_total_sales_show: 0,
        total_sales: 0,
        total_sales_fail: 0,
        total_sales_ok: 0,
        virtual_total_sales_fail: 0,
        virtual_total_sales_ok: 0,
        virtual_total_sales_show: 0,
      },
      location: "",
      active: 1,
      eventoptions: null,
      // CHART
    };
  },
  methods: {
    ...mapActions([
      "loadTypestationsActive",
      "loadAnalyticStation",
      "loadCategorystations",
      "storeStation",
      "addToastMessage",
      "loadStaffsByEvent",
      "loadDevicesByEvent",
      "loadProductTransactionsDuplicated",
      "loadTotalDevices"
    ]),
    showVisorStation() {

      let codeevent = this.codeevent;
      console.log('CODE EVENT'+codeevent)
      //Convert codeevent to base64
      let base64CodeEvent = btoa(codeevent);

      let name_station = this.station.name;
      let id_station = this.station.id;
      console.log('NAME STATION'+name_station)

      
      let generation = name_station+'...'+id_station;
      //Convert generation to base64
      let base64CodeStation = btoa(generation);

     //Go to external route on new tab
      window.open(
        'https://visor.protaps.app/'+base64CodeEvent+'/station/'+base64CodeStation,
        "_blank"
      );
    },
    getDuplicates() {
      this.loadProductTransactionsDuplicated({ id_station: this.$route.params.idstation })
        .then((data) => {
          this.duplicateTransaction = data;

          for (let i = 0; i < data.length; i++) {
            this.amountDuplicated += data[i].standard_credit;
          }
        })
        .catch((data) => {
          this.addToastMessage({
            text: "Error consultando",
            type: "warning",
          });
        });
    },
    getTotalDevices() {
      this.loadTotalDevices({ id_station: this.$route.params.idstation })
        .then((data) => {
          this.totalDevices = data;

        
        })
        .catch((data) => {
          this.addToastMessage({
            text: "Error consultando",
            type: "warning",
          });
        });
    },
    showDeviceDetail() {
      this.getTotalDevices();
      this.$refs.deviceDetailModal.open();
      this.showModalDeviceDetail = true;
    },
    showDuplicateDetail() {
      this.getDuplicates();
      this.$refs.duplicateDetailModal.open();
      this.showModalDuplicate = true;
    },
    downloadStationSalesReport() {
      let url = `${Config.apiPath}report/excel/station_sales_details/${this.$route.params.idstation}/download`;
      if (this.selectedStaff) {
        url += `?id_staff=${this.selectedStaff.id}`;
      }
      if (this.selectedDevice) {
        url += `?id_device=${this.selectedDevice.id}`;
      }
      if (this.selectedStaff && this.selectedDevice) {
        url = url.replace("?id_device", "&id_device");
      }
      window.open(url, "_blank");
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
      this.showModalDuplicate = false;
      this.showModalDeviceDetail = false;
    },
    backPage: function () {
      this.$router.go(-1);
    },
   
    getStaffByEvent() {
      this.loadStaffsByEvent({ id: this.idevent })
        .then((data) => {
          this.staffData = data;
        })
        .catch((data) => {
          this.addToastMessage({
            text: "Error consultando",
            type: "warning",
          });
        });
    },
    getDevicesByEvent() {
      this.loadDevicesByEvent(this.idevent)
        .then((data) => {
          this.devicesData = data.map((x) => x.device);
        })
        .catch((data) => {
          this.addToastMessage({
            text: "Error consultando",
            type: "warning",
          });
        });
    },
    saveStaff() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.storeStation({
            name: this.name,
            number: this.number,
            location: this.location,
            id_event: this.idevent,
            active: this.active,
            id_type_station: this.typestation.id,
          })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: "success",
              });
              setTimeout(() => this.$router.replace("/station"), 2000);
            })
            .catch((data) => {
              this.error = data.message;
              this.addToastMessage({
                text: data.message,
                type: "warning",
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
